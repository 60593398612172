import React, { FC } from 'react'
import { Layout } from '../components/Layout'
import { FourOhFour, FourOhFourLocalization } from '../components/404/FourOhFour'

interface Props {
  localization: FourOhFourLocalization
}

const PageNotFound: FC<Props> = props => {
  const { localization } = props
  return (
    <Layout>
      <FourOhFour localization={localization} />
    </Layout>
  )
}
export default PageNotFound

export const getStaticProps = () => {
  const localization: FourOhFourLocalization = {
    title: 'Ooops!',
    description: 'Looks like your are lost',
    button: 'Go to main page',
  }

  return {
    props: {
      localization,
    },
  }
}
